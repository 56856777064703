<!-- 系统设置 app管理 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'sys:dictlist:add'" type="plain" @click="add">新增</ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <AddOrEdit
      @close="addEditDrawerVisible = false"
      :editInfo="editInfo"
      v-if="addEditDrawerVisible"
      v-model="addEditDrawerVisible"
      @finish="searchTableList" />
  </scroll-layout>
</template>

<script>
  import AddOrEdit from './AddOrEdit.vue';
  import { sysDict, upgradeManage } from '@/service/apis';
  import { code } from '@/utils';

  export default {
    name: 'accountManageList',
    components: {
      AddOrEdit,
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '字段名称 ',
            placeholder: '请输入字段名称',
          },
          {
            comName: 'YkcInput',
            key: 'dictType',
            label: '类型',
            placeholder: '请输入类型',
          },

          {
            comName: 'YkcInput',
            key: 'dictCode',
            label: '编码 ',
            placeholder: '请输入编码',
          },
          // {
          //   comName: 'YkcInput',
          //   key: 'updateContent',
          //   label: '描述',
          //   placeholder: '请输入描述',
          // },
        ];
      },
    },
    data() {
      return {
        addEditDrawerVisible: false,
        tableData: [],
        editInfo: {},
        tableColumns: [
          { label: '编码', prop: 'code', minWidth: '200px' },
          { label: '名称', prop: 'name', minWidth: '100px' },

          {
            label: '字典类型',
            prop: 'dictType',
            minWidth: '100px',
          },
          {
            label: '排序',
            prop: 'sort',
            minWidth: '100px',
          },
          {
            label: '字典描述',
            prop: 'remark',
            minWidth: '100px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.remark} placement="top-start">
            //         <span>{row.remark}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
        ],
        tableTitle: '字典列表',
        tableOperateButtons: [
          {
            enabled: () => code('sys:dictlist:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  console.log(h, data);
                  this.editInfo = row;
                  this.addEditDrawerVisible = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          dictType: '',
          name: '',
          // versionNo: '',
          // releaseUrl: '',
          // updateContent: '',
        },
      };
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      /** 根据条件查询数据 */
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        sysDict
          .dictList(reqParams)
          .then(res => {
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      // 启用
      startUpgrade(row) {
        upgradeManage
          .editUpgrade({
            id: row.id,
          })
          .then(() => {
            this.searchTableList();
          });
      },
      /** 新增 */
      add() {
        this.editInfo = {};
        this.addEditDrawerVisible = true;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table-cell-wrap-enable {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #02c498;
      border-radius: 50%;
    }
  }
  .ykc-table-cell-wrap-deactivate {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #d0021b;
      border-radius: 50%;
    }
  }
</style>
