<!-- 系统设置 版本管理 新增或编辑 -->
<template>
  <ykc-drawer
    :show="value"
    destroy-on-close
    :title="drawerTitle"
    @update:show="$emit('input', $event)">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form ref="YkcForm" :model="model" :rules="rules">
        <ykc-form-item label="字段名称" prop="name">
          <ykc-input maxlength="50" placeholder="请输入字段名称" v-model="model.name"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="编码" prop="code">
          <ykc-input maxlength="50" placeholder="请输入编码" v-model="model.code"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="类型" prop="dictType">
          <ykc-input maxlength="50" placeholder="请输入类型" v-model="model.dictType"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="排序" prop="sort">
          <ykc-input maxlength="4" placeholder="请输入排序" v-model="model.sort"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="字典描述" prop="remark">
          <ykc-input
            v-model="model.remark"
            type="textarea"
            maxlength="500"
            placeholder="请输入字典描述"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { sysDict } from '@/service/apis';

  export default {
    props: {
      editInfo: {
        type: Object,
        default: () => {
          return {};
        },
      },
      value: {
        type: Boolean,
      },
    },
    data() {
      return {
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.onAddDrawerCancel();
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.beforeEnsure();
            },
          },
        ],
        model: {
          name: '',
          code: '',
          sort: '',
          dictType: '',
          remark: '',
        },
        rules: {
          name: [{ required: true, message: '请输入字段名称', trigger: 'blur' }],
          code: [{ required: true, message: '请输入编码', trigger: 'blur' }],
          dictType: [{ required: true, message: '请输入类型', trigger: 'blur' }],
          sort: [
            { required: true, message: '请输入排序', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.decimalNumberType.regexp,
              message: regexpObj.regexp.input.decimalNumberType.errorTips.error('排序', 9999),
              trigger: 'blur',
            },
          ],
          remark: [{ required: true, message: '请输入字典描述', trigger: 'blur' }],
        },
      };
    },
    mounted() {
      console.log('====', this.editInfo);
      if (this.editInfo.id) {
        this.model = {
          ...this.editInfo,
        };
      }
    },
    computed: {
      drawerTitle() {
        return this.editInfo.id ? '字典编辑' : '字典新增';
      },
    },

    methods: {
      // 抽屉展示
      // handleOpen() {
      //   this.drawerTitle = '新增字典信息';
      // },

      // 保存
      beforeEnsure() {
        this.$refs.YkcForm.validate(async valid => {
          if (valid) {
            const reqParams = {
              name: this.model.name,
              code: this.model.code,
              dictType: this.model.dictType,
              remark: this.model.remark,
              sort: this.model.sort,
            };
            if (this.editInfo.id) {
              reqParams.id = this.editInfo.id;
              sysDict.dictUpdate(reqParams).then(() => {
                this.$emit('close');
                this.$emit('finish');
              });
            } else {
              sysDict.dictAdd(reqParams).then(() => {
                this.$emit('close');
                this.$emit('finish');
              });
            }
          }
        });
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel() {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            this.$emit('close');
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
